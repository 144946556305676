html,
body {
  max-width: 100%;
  overflow-x: hidden;
  height: 100%;
}

:root {
  --appcolor: #013289;
}

#root {
  height: 100%;
  overflow-y: hidden;
}
#root > div {
  height: 100%;
  overflow-y: hidden;
  position: relative;
}

.innerContainer {
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.innerContainer main {
  height: calc(100% - 90px);
  overflow-y: auto;
  overflow-x: hidden;
}
.main-footer {
  position: relative;
  bottom: 0px;
  width: 100%;
  /* background-color: white !important; */
  text-align: center;
  height: 30px !important;
  max-height: 30px !important;
  min-height: 30px !important;
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 50%);
  padding-top: 2px !important;
}
.react-confirm-alert-body h1 {
  font-size: 24px;
}

.main-footer img {
  width: 80px;
  text-align: center;
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 50%;
  height: 50%;
  margin: 0 auto 10px;
  display: block;
}

.usernamelbl {
  color: white;
}

.editableGrid {
  height: 300px;
  width: "100%";
  overflow-x: visible;
}
.editableSmGrid {
  height: 200px;
  width: "100%";
  overflow-x: visible;
}

.editableGrid .ag-cell {
  border-right-color: #ddd !important;
  border-right-width: 1px !important;
  border-right-style: solid !important;
}

.editableSmGrid .ag-cell {
  border-right-color: #ddd !important;
  border-right-width: 1px !important;
  border-right-style: solid !important;
}

/* 
 .editableGrid  .ag-row:last-child .ag-cell::after{
   content: 'add text';
   color:#ddd
 } */

.selectInputContainer .form-control {
  padding: 0px;
}

.wizard-modal {
  min-width: 80vw !important;
  min-height: 80vh !important;
}
.extras-modal {
  min-width: 50vw !important;
  min-height: 80vh !important;
}

.mainContainer {
  padding-top: 10px;
  position: relative;
  padding-left: 1% !important;
  padding-right: 1% !important;
}

.menunav {
  position: sticky !important;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.7);
  height: 60px;
}
.menunav .container-fluid{
  padding-right: 0%;
}
.menunav a {
  font-size: large;
}
.profile-dropdown.nav-item.dropdown > a > img {
  position: relative;
  z-index: -1 !important;
}
.profile-dropdown .dropdown-menu {
  right: 0;
}
.profile-dropdown ::after {
  content: none;
}
.navWixard {
  margin-bottom: 15px;
  text-align: center;
}

.dot {
  color: black;
  font-size: 36px;
  line-height: 1;
  margin: 0 15px;
  opacity: 0.4;
  text-shadow: none;
  transition: opacity 1s ease, text-shadow 1s ease;
  will-change: opacity, text-shadow;
}
.language-dropdown.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

#basic-navbar-nav .active {
  color: var(--blue);
  opacity: 1;
  text-shadow: 0 0px 8px;
}
#basic-navbar-nav .nav-item {
  max-height: 60px;
}

/* .active {
  color: var(--blue);
  opacity: 1;
  text-shadow: 0 0px 8px;
} */

/* .saleActions{
  position:fixed;
  left:85%;
  top:80%
}

.saleActions .btn{
  background-color: transparent;
  color:black;
} */
/* 
.footer{
  margin-top: 1rem;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
} */

/*  ===========SelectTable styles========== */

.selectTableHeader {
  background-color: var(--appcolor);
  height: 40px;
  padding: 0px;
  padding-top: 8px;
}

.optionSelectTableRow {
  padding: 0px !important;
  margin: 0px !important;
}

.optionSelectTableRow > div {
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding: 6px !important;
}
.selectTableMenuList > div {
  padding: 0px;
}

.selectTableMenuList > div {
  /* padding: 0px !important;
  margin:0px !important; */
  /* border-bottom: 1px solid black; */
}

.footerRow {
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  height: 30px;
}

.footerRow .internal-footerRow {
  background-color: #eee;
  height: 100%;
  border: 1px solid #bbb;
}

.selectTableDropDown > .container-fluid {
  background-color: var(--appcolor);
  padding-left: 2px;
  padding-right: 2px;
}

.ag-root-wrapper {
  overflow: visible !important;
}

.selectTableDropDown {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/*  ===========End SelectTable styles========== */

.cornerimg {
  position: absolute;
  width: 80px;
  height: 80px;
  right: 0;
}

.salesContainer {
  position: relative;
  padding-bottom: 10px;
}

.total_label,
.noticed_label {
  float: left;
}
.total_value,
.noticed_value {
  float: left;
}

.noticed_label,
.noticed_value {
  font-size: large;
  font-weight: bold;
}

/* 
.dashboard-card {
  max-width:20rem
} */

.circle-btn {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

/* ========================================== */

.addtextafter {
  /* content: 'add text'; */
  color: #bbb;
  text-align: center;
  font-size: large;
}

.addproduct::after {
  content: "add text";
  color: #ddd;
}

.addquantity::after {
  content: "Add quantity";
  color: #ddd;
}
/* ========================================== */

.form-group.required .control-label:after {
  content: "(*)";
  color: red;
  margin-left: 4px;
}
.inputlabel {
  font-size: 14px;
}

.infoicon {
  margin-left: 4px;
  color: var(--appcolor);
}

#salesMoreAccordion,
#taxesMoreAccordion,
#deliveryMoreAccordion {
  border-top: none;
  border-left: none;
  border-right: none;
}
#salesMoreAccordion > .accordion-item {
  border: none;
}

#taxesMoreAccordion > .accordion-item {
  border: none;
}
#deliveryMoreAccordion > .accordion-item {
  border: none;
}

.addmodelBtnContainer .btn {
  float: right;
}

#selectCompanyChoiceBtn {
  border: none;
  font-size: 14px;
  margin-top: 8px;
  font-weight: bold;
  margin-left: 0%;
  padding-left: 0%;
}

.fa.canceledInvoice {
  color: red;
}

/* .ag-header-cell.required .ag-cell-label-container:after {
  content: "(*)";
  color: red;
  margin-left: 4px;
  font-size: 12px;
}
.ag-header-cell.info .ag-cell-label-container:after {
  content: "(!)";
  color: white;
  margin-left: 4px;
  font-size: 12px;
} */

.custom-date-filter a {
  position: absolute;
  right: 20px;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}
/* .custom-date-filter:after {
  position: absolute;
  content: '\f073';
  display: block;
  font-weight: 400;
  font-family: 'Font Awesome 5 Free';
  right: 5px;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.54);
} */

.custom-date-filter .react-datepicker-popper {
  position: relative !important;
}

.headerRow {
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  line-height: 2;
  height: 40px;
  font-size: small;
}

.payvivabtn > img {
  width: 40px;
  height: 40px;
}

#salesLeft {
  position: absolute;
  right: 0;
  margin: 10px;
  top: -6px;
  z-index: 100;
}
.dashboardCard,
.companyCard {
  padding: 10px 15px 10px;
  margin-top: 20px;
}

.dashboardCard span a {
  color: white;
}

.dashboardHeader {
  margin-top: 10px;
  color: var(--appcolor);
}

.recapctha-container > div > div > div {
  width: auto !important;
  height: auto !important;
  margin-top: 10px;
}
.customerCard {
  margin-top: 0px;
  padding: 8px;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 15%);
  background-color: white;
  border:none
}
.customerCard .card-header{
  padding-left: 0;
  background-color: white;

}
.mainInvoiceInfoCard {
  margin-top: 6px;
  margin-bottom: 0px;
  padding: 6px;
  background-color: white;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 15%);
  border:none
}

.innerViewContainer {
  height: 240mm;
  /* width: 211mm;
  border: 1px solid #bbb;
  text-align: center;
  position: relative;
  margin-left: calc(50% - 110mm);
  background-color: white; */
  margin-bottom: 20px;
  /* margin-top:50px; */

  overflow: auto;
}
.accordion-button.nonhilight:not(.collapsed) {
  background-color: inherit;
}

.accordion-button.nonhilight:focus {
  border-color: inherit;
}

/* #lhc-node-id {
  position:relative
}
#lhc-node-id > div{
  position:relative !important
}
#lhc_status_widget_v2{
  position:relative !important
} */

#lhc_status_widget_v2 {
  display: none !important;
}

#lhc-node-id {
  margin-top: 6px;
  padding: 0px;
  cursor: pointer;
}

.totalConstraintInfo {
  position: absolute;
  right: 20px;
  top: -40px;
  font-size: 12px;
}

div.react-confirm-alert-overlay {
  z-index: 9999;
}
.salefooter {
  margin-top: 10px;
  padding: 4px;
  margin: 0%;
}

.categoryselect {
  position: absolute !important;
  right: 0px;
  top: 0px;
}

.productAccordion .accordion-button {
  padding: 0px !important;
}

.fetchTaxPayerId {
  background-color: var(--appcolor);
  color: white;
  cursor: pointer;
}

.product_entries_table_container div[ref="eRootWrapper"] {
  border: none !important;
}
.product_entries_table_container {
  margin: 0%;
  padding: 0%;
}

#collapseTaxes div[ref="eRootWrapper"] {
  border: none !important;
}

.menunav ul[role="listbox"] {
  background-color: var(--appcolor);
}

.login-register-container {
  height: 100%;
  overflow-y: auto;
}
.footermess {
  line-height: 0.8;
}

.footermess > span {
  font-size: 10px;
  color: #6c757d;
}

.classificationTemplatesMess {
  font-size: 12px;
}

.confirm-card {
  padding: 0px;
}

.confirm-card .card-header {
  font-size: 16px;
}

.confirm-card .card-footer .btn {
  margin-right: 10px;
}
.confirm_logo {
  margin-right: 10px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  .main-footer {
    height: 70px !important;
  }
  .internal-header .header-btn {
    position: relative;
    text-align: center;
    right: 0px;
    width: 100%;
  }

  .internal-card {
    margin-top: 10px;
    padding: 6px;
  }
  .internal-card .card-body {
    padding: 0px;
  }
  

  .menunav {
    height: auto;
  }

  .search_header {
    padding-top: 0% !important;
    padding-bottom: 0% !important;
  }
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: flex;
  }

  .ag-cell-value {
    font-size: 12px !important;
  }
  .salesContainerParent{
    padding-left: 0;
    padding-right: 0;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .internal-card {
    margin-top: 10px;
    padding: 6px;
  }
  .internal-card .card-body {
    padding: 0px;
  }

  .menunav {
    height: auto;
  }
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: flex;
  }
  .ag-cell-value {
    font-size: 12px !important;
  }
  .salesContainerParent{
    padding-left: 0;
    padding-right: 0;
  }
}
/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) {
  .internal-card {
    margin-top: 10px;
    padding: 6px;
  }
  .internal-card .card-body {
    padding: 0px;
  }
  .desktop-only {
    display: none;
  }
  .ag-cell-value {
    font-size: 14px !important;
  }
  .mobile-only {
    display: none;
  }
  .salesContainerParent{
    padding-left: 0;
    padding-right: 0;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .internal-card {
    margin-top: 10px;
    padding: 6px;
  }
  .internal-card .card-body {
    padding: 4px;
  }
  .salesContainerParent{
    padding-left: 0;
    padding-right: 0;
  }
  .menunav {
    height: 60px;
  }
  .mobile-only {
    display: none;
  }
  .desktop-only {
    display: unset;
  }
  .ag-cell-value {
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 1440px){
  .internal-card {
    margin-top: 10px;
    padding: 6px;
  }
  .internal-card .card-body {
    padding: 4px;
  }
  .salesContainerParent{
    padding-left: 1%;
    padding-right: 1%;
  }
  .mobile-only {
    display: none;
  }
}
  @media only screen and (min-width: 1920px){
    .internal-card {
      margin-top: 10px;
      padding: 6px;
    }
    .internal-card .card-body {
      padding: 4px;
    }
    .salesContainerParent{
      padding-left: 3%;
      padding-right: 3%;
    }
    .mobile-only {
      display: none;
    }
}

.block-ui-message-container {
  text-align: center;
}

.square-spin > div {
  width: 80px;
  height: 80px;
  border-radius: 6%;
  margin-left: calc(50% - 40px);
  background: var(--appcolor);
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid white;
  background-image: url(../img/t-logo-an.png);
  background-size: 80px 80px;
}

.block-ui-message-container {
  top: 280px;
}

/* .spinskin >div {
  width: 50px;
  height: 50px;
  background-image: url("https://i.imgur.com/kDDFvUp.png");
  -webkit-animation: square-spin 3s 0s cubic-bezier(.09,.57,.49,.9) infinite;
  animation: square-spin 3s 0s cubic-bezier(.09,.57,.49,.9) infinite;
} */

.header-btn {
  position: absolute;
  right: 20px;
  top: 8px;
}


.totalsparent .totals {
  padding: 2% !important;
}
.confirm-card .react-confirm-alert-button-group {
  margin-top: 0px;
}

.totalsparent .totals .d-flex {
  padding-left: 2% !important;
  padding-bottom: 0% !important;
  padding-top: 0% !important;
}

.totalsparent .totals .d-flex .p-2 {
  padding: 4px !important;
}

.profile-dropdown button {
  background-color: transparent;
  border: none;
}
.profile-dropdown button:hover {
  background-color: transparent;
  /* border:none */
}

.profile-dropdown > .dropdown-menu.show {
  display: inline-flex;
  padding: 2px;
}

.sales-table-container div.ag-theme-alpine .ag-header-cell-text {
  white-space: break-spaces;
}
.sales-entries-table-container .ag-header-cell-text {
  white-space: break-spaces;
}

.sales-entries-table-container .ag-header-cell {
  padding: 6px;
  margin: 0%;
  font-size: 11px;
  text-align: center;
  margin: center;
  /* display:grid */
}

.sales-entries-table-container .ag-cell.ag-cell-value.ag-cell-wrap-text {
  padding-left: 4px;
}

.Toastify__toast-body {
  font-weight: 700 !important;
}

.select-footer-button {
  width: 40px;
  float: right;
}

.view_invoice_frame_container {
  -webkit-box-shadow: 5px 5px 5px 5px #bbb;
  box-shadow: 5px 5px 5px 5px #bbb;
  height: 100vh;
}

/* .Toastify__toast-container{
  width:fit-content
} */
.react-select-footer {
  height: 40px;
  background-color: #eee;
}
.react-select-shortcut {
  margin-right: 10px !important;
  height: 30px;
  margin-top: 5px;
}

.percentInfoIcon {
  margin-right: 6px;
}

.ag-cell {
  user-select: initial;
  -moz-user-select: text;
  -ms-user-select: text;
  -webkit-user-select: text;
}

.cancelationMark-span {
  position: absolute;
  color: red;
  top: 8px;
}

.mark-span {
  position: absolute;
  bottom: 8px;
}
.editable-entry-placeholder {
  font-size: 12px;
}

.pricing-box {
  padding: 40px 20px;
  background: #fff;
  text-align: center;
  box-shadow: 0px 0 30px rgb(1 41 112 / 8%);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
}

.left-table .ag-body-viewport {
  overflow-y: hidden;
  margin: 0% !important;
  padding: 0% !important;
}

.left-table {
  margin: 0% !important;
  padding: 0% !important;
}

.right-table {
  margin: 0% !important;
  padding: 0% !important;
}

.left-table .ag-paging-panel.ag-unselectable span {
  visibility: hidden;
}

.mark_cell a {
  margin-left: 6px;
}
.mark_cell {
  margin-right: 6px;
}
.salesTaxesGridInfo {
  color: #6c757d;
}

.details_child_table {
  margin: 0px;
}
.details_child_table td {
  max-width: 200px;
  width: 200px;
}

.details_child_table td:nth-child(1) {
  border-right: 1px solid #ddd;
}
.details_child_table td:nth-child(3) {
  border-left: 1px solid #ddd;
}

.details_table td:nth-child(1) {
  width: 10%;
}
.details_table td:nth-child(2) {
  width: 6%;
}
.details_table td:nth-child(3) {
  width: 6%;
}
.details_table td:nth-child(3) {
  width: 6%;
}
.details_table td:nth-child(4) {
  width: 36%;
}
.details_table td:nth-child(5) {
  width: 36%;
}

.more_tax_child_table td:nth-child(1) {
  width: 30%;
}
.more_tax_child_table td:nth-child(2) {
  width: 60%;
}
.more_tax_child_table td:nth-child(3) {
  width: 10%;
}

.invoice-viewcontent-container {
  height: 90vh;
  position: relative;
}
.invoice-viewcontent {
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.invoice-content {
  height: 100%;
}
.salefooter .sale_action {
  padding: 6px;
}
.designer-frame-container {
  padding: 0%;
  overflow-x: scroll;
  /* overflow-x: hidden;
  overflow-y: hidden; */
  -webkit-overflow-scrolling: touch;
  border: 1px solid #ddd;
}

.designer-frame-container iframe {
  box-shadow: 0px -4px 10px rgb(0 0 0 / 50%);

}

.yearlyPercentDiscount {
  margin-left: 10px;
}
.cssInput{
  font-size: 12px;
  padding: 4px;
}
/* div[class^="b_423l3bol"] > div > div {
  box-shadow:none !important;
  border-radius: 0px !important;
  -webkit-box-shadow: none
} */
/* iframe{

  -ms-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -o-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  transform: scale(0.5);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
} */

/* .ag-cell-value{
  overflow: visible;
} */
.szh-menu-button {
  background-color: transparent;
  border: none;
}

.ag-cell-value[col-id="seriesNumber"] {
  overflow: visible;
  /* padding-left:4px; */
}

.ag-cell-value {
  font-size: 14px;
}

.ag-cell-value[col-id="action"] {
  overflow: visible;
}
.szh-menu__item {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
.szh-menu__item i {
  margin-right: 10px;
}
.startSubscription-container {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.blob.red {
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);

  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.flagIconDropDown .dropdown-menu {
  /* width:60px */
  display: flex;
}

.flagIcon {
  margin: 10px;

}
.right-panel-designer-card {
  border: none;
  background-color: transparent;
}
.left-panel-designer-card {
  margin-top: 0px;
  border: none;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}
.right-panel-designer-card .inputlabel {
  margin-right: 4px;
  margin-left: 4px;
}
.ag-overlay-loading-center {
  padding: 6px;
}
.ag-overlay-loading-center img {
  margin: 4px;
}

.recharts-text.recharts-cartesian-axis-tick-value tspan {
  font-size: 11px;
}

.alert.small-info {
  font-size: 13px !important;
  padding: 4px;
}
.series-menu {
  margin-right: 6px;
}
/* .seriesNumber::before {
  content: "\25BE";
  margin-right: 6px;
  margin-left: 0px;
  color: #bbb;
} */

.extra-company-field-card {
  padding: 6px;
  background-color: white;
}
.sales-aggrid-tooltip {
  position: absolute;
  width: 300px;
  height: 160px;
  border: 1px solid cornflowerblue;
  transition: opacity 1s;
}

.sales-aggrid-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.templateLangContainer button {
  border: none;
}
.tooltip-canceled-status{
  color:red;
}
.tooltip-sended-status{
  color:green;
}
.allFieldsColumnRow{
  height:20px
}
.allFieldsColumnRow th {
  padding: 0%;
}
.allFieldsColumnRow td {
  padding: 0%;
}
.allFieldsColumnRow tbody {
  line-height: 0;
}
.allFieldsColumnRow thead {
  line-height: 3;
}
/* ----------------------------------------------------------------- */
.sales-mobile-table-container .ag-header {
  height:0px !important;
  min-height: 0px !important;
}

.customer-mobile-table-container .ag-header {
  height:0px !important;
  min-height: 0px !important;
}

.product-mobile-table-container .ag-header {
  height:0px !important;
  min-height: 0px !important;
}

.invoiceType-mobile-table-container .ag-header {
  height:0px !important;
  min-height: 0px !important;
}



.invoiceType-mobile-table-container .ag-header {
  height:0px !important;
  min-height: 0px !important;
}


/* ----------------------------------------------------------------- */



.sales-mobile-table-container .ag-cell-value[col-id="invoiceType"] {
  padding-left:2px !important;
  padding-right:0px !important;
  line-height: 20px;
}

.customer-mobile-table-container .ag-cell-value[col-id="name"] {
  padding-left:2px !important;
  padding-right:0px !important;
  line-height: 20px;
}

.product-mobile-table-container .ag-cell-value[col-id="name"] {
  padding-left:2px !important;
  padding-right:0px !important;
  line-height: 20px;
}

.invoiceType-mobile-table-container .ag-cell-value[col-id="name"] {
  padding-left:2px !important;
  padding-right:0px !important;
  line-height: 20px;
}



/* ----------------------------------------------------------------- */


.sales-mobile-table-container .ag-cell-value[col-id="action"] {
  padding-left:6px !important;
  padding-right:0px !important;
  line-height: 20px;

}
.product-mobile-table-container .ag-cell-value[col-id="action"] {
  padding-left:2px !important;
  padding-right:0px !important;
  line-height: 20px;
}

.invoiceType-mobile-table-container .ag-cell-value[col-id="action"] {
  padding-left:2px !important;
  padding-right:0px !important;
  line-height: 20px;
}



.customer-mobile-table-container .ag-cell-value[col-id="action"] {
  padding-left:6px !important;
  padding-right:0px !important;
  line-height: 20px;

}

/* ----------------------------------------------------------------- */


.sales-mobile-table-container .ag-paging-panel .ag-paging-row-summary-panel{
  margin: 0;
}

.product-mobile-table-container .ag-paging-panel .ag-paging-row-summary-panel{
  margin: 0;
}


.invoiceType-mobile-table-container .ag-paging-panel .ag-paging-row-summary-panel{
  margin: 0;
}




.customer-mobile-table-container .ag-paging-panel .ag-paging-row-summary-panel{
  margin: 0;
}


/* ----------------------------------------------------------------- */

.product-mobile-table-container .ag-paging-panel{
  height: 30px;
}

.invoiceType-mobile-table-container .ag-paging-panel{
  height: 30px;
}

.sales-mobile-table-container .ag-paging-panel{
  height: 30px;
}
.customer-mobile-table-container .ag-paging-panel{
  height: 30px;
}

/* ----------------------------------------------------------------- */


.sales-mobile-table-container  .szh-menu.szh-menu.szh-menu{
  line-height: 40px !important;
}
.subtitle{
  font-size: small;
}
.searchSalesInputContainer{
  padding-left: 0 !important;
}
.sales-grid-row{
  padding: 0;
  padding-left:2px;
  border-left:6px solid red
}

.sales-grid-row{
  padding: 0;
  padding-left:6px;
  border-left:3px solid #bbb
}

.sales-grid-row.row-canceled-status{
  border-left:3px solid red
}

.sales-grid-row.row-sended-status{
  border-left:3px solid green
}

.column-choicer div {
  border:none
}
.DateInput_input{
  padding: 4px !important;
  font-size: small !important;
  background-color: #eee !important;
  padding-top: 0;
  padding-bottom: 0;

}
.DateInput{
  width:80px !important;
  font-size: 11px !important;
  text-align: center !important;
 
}
.DateRangePickerInput_arrow{
  display: none !important;
}
.DateRangePickerInput{
  border: none !important;
  width: 80px !important;
}
/* .CalendarDay__default{
  width:10px !important;
  height:10px !important;
}
.DayPicker_weekHeader_li{
  width:10px !important;
  height:10px !important;
} */

.tippy-box{
  background-color: #013289 !important;
  color:white;
}

.sales-mobile-table-container .ag-root-wrapper-body {
  box-shadow: 0px 4px 10px rgb(0 0 0 / 50%) inset;

}

.sales-mobile-table-container .ag-paging-panel{
  box-shadow: 0px -4px 10px rgb(0 0 0 / 50%);
}

.sales-mobile-table-container  .ag-center-cols-container div {
  background-color: transparent;
}

.sales_mark_attention_message{
  padding: 10px;
  margin-top: 16px;
}

.customer-mobile-table-container .ag-root-wrapper-body {
  box-shadow: 0px 4px 10px rgb(0 0 0 / 50%) inset;

}

.customer-mobile-table-container .ag-paging-panel{
  box-shadow: 0px -4px 10px rgb(0 0 0 / 50%);
}

.customer-mobile-table-container  .ag-center-cols-container div {
  background-color: transparent;
}

.customer-grid-row{
  padding: 0;
  padding-left:4px;
  border-left:3px solid var(--appcolor);
}

.customerContainerParent{
  padding: 0%;
}

.fetchTaxPayerId{
  height:40px;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 40%;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 40px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}

.main-nav-right-btns button{
  border:none
}

.chooseCompanySelectContainer{
  padding-left:4px;
  padding-right:4px;
}

.company-modal{
  padding-left: 0px !important;
}

.card-simple-view-parent{
  padding:4px;
}

.card-simple-view{
  padding:4px;
  font-size: 12px;
}

.card-simple-view .card-body{
  padding:4px
}

.send-email-modal .modal-title{
  font-size: small;
}

.send-email-modal .modal-footer{
  padding:6px;
}
.send-email-modal .modal-body{
  padding:6px;
}

.transport_information_container.card{
  padding: 6px;
}
.profile-dropdown .dropdown-menu{
  position: absolute !important;
}

.wizard-inner-container{
  padding:4px
}

.wizard-inner-container .container-fluid{
  padding:4px
}

.navWizard{
  padding:4px
}
.wizard-modal-body{
  padding:4px
}

.pricing-box ul{
  list-style-type: none;
}

.divider {
  font-size: 18px;
  display: flex;
  align-items: center;
}

.divider::before, .divider::after {
  flex: 1;
  content: '';
  padding: 1px;
  background-color: #bbb;
  margin: 5px;
}

.rowLine{margin:25px}
.rowLine h2{font-size:15px; text-align:left; border-bottom:1px solid #ccc; position:relative; }
.rowLine h2 span { background-color: white; position: relative; top: 10px; padding: 0 10px;}

.chatwindow-container{
  width:400px;
  height:600px;
  position: fixed;
  bottom: 0;
  right:0;
}

.close-chat{
  position: absolute;
  width:60px;
  height:60px;
  left:-60px;
  bottom:0px;
  background-color: var(--appcolor);
}

.chatsidebar {
  background: #edf1f3;
  height: 100%;
  width: 60%;
  position: absolute;
  right: 0;
}

.chatwindow-container.hide {
    transform: translateX(400px);
    transition-duration: 1s;

}
.chatwindow-container.show {
  right: -400px;
  transform: translateX(-400px);
  transition-duration: 1s;

}
