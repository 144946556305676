html,
body {
  background-color: #eee;
}
:root {
  --appcolor: #013289;
}

.skin {
  color: var(--appcolor);
}

.menunav,
.dropdown-menu {
  background-color: var(--appcolor) !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important; 
}

.card-header-skin {
  color: white;
  background-color: var(--appcolor) !important;
}

.menunav a {
  color: white !important;
}

.flagSelect span {
  color: white !important;
}

.salesContainer {
  -webkit-box-shadow: 0px 0px 7px 0px rgba(165, 165, 165, 0.85);
  box-shadow: 0px 0px 7px 0px rgba(165, 165, 165, 0.85);
  background-color: white;
}

.ag-header-viewport {
  background-color: var(--appcolor);
}

.ag-header-cell {
  color: white !important;
}

.form-control {
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  box-shadow: 1px 1px #eee;
}
.inputlabel {
  /* display:none; */
  color: rgb(129, 129, 129);
}

.mainContainer {
  padding-left: 2%;
  padding-right: 2%;
}
/* #basic-navbar-nav{
  opacity: 0.8  !important;
} */
#basic-navbar-nav .nav-item {
  /* border-right:1px solid white; */
  /* border-right: 1px solid rgba(255, 255, 255, 0.2); */
}
.addmodelBtnContainer i {
  margin-left: 6px;
}

.btn-skin-secondary {
  background-color: #eee;
}

.menunav .dropdown-item:hover {
  color: white;
  background-color: #456fb8;
}

.menunav .dropdown-item.active {
  color: var(--appcolor) !important;
  background-color: white;
}

.internal-footer,
.internal-header {
  background-color: transparent;
  padding-left: 4px;
  padding-right: 4px;
}

.internal-card {
  background-color: white;
}
.btn-skin {
  background-color: var(--appcolor) !important;
  color: white !important;
}

.card-header-skin {
  background-color: var(--appcolor);
  color: white;
}

.companyCard a {
  text-decoration: none;
  color: var(--appcolor);
}

/* .skin-tooltip {
  max-width: 300px;
  word-break: break-all;
  background-color: #FFF !important;
  color: #013289 !important;
  border:1px solid #013289 !important;
  opacity: 90% !important;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(165, 165, 165, 0.85);
  box-shadow: 0px 0px 7px 0px rgba(165, 165, 165, 0.85);
  font-weight: 700;
  font-size: 16px;

} */
.skin-tooltip {
  max-width: 300px;
  word-break: break-all;
}
.totals {
  background-color: var(--appcolor);
  border: none;
  box-shadow: none;
  color: white;
}

.totals .card-footer {
  background-color: transparent;
  border-top: 1px solid white !important;
  padding: 0%;
}


.classificationGrid div[ref="eRootWrapper"] {
  border: none !important;
}

.classificationGrid .ag-center-cols-viewport {
  background-color: white;
}

.salesTaxesGrid div[ref="eRootWrapper"] {
  border: none !important;
}

.salesTaxesGrid .ag-center-cols-viewport {
  background-color: white;
}

.salesClassicicationsGrid div[ref="eRootWrapper"] {
  border: none !important;
}
.salesClassicicationsGrid .ag-center-cols-viewport {
  background-color: white;
}


.customerContactsGrid div[ref="eRootWrapper"] {
  border: none !important;
}

.customerContactsGrid .ag-center-cols-viewport {
  background-color: white;
}

.taxesGrid div[ref="eRootWrapper"] {
  border: none !important;
}

.taxesGrid .ag-center-cols-viewport {
  background-color: white;
}

.seriesGrid .ag-center-cols-viewport {
  background-color: white;
}

.seriesGrid div[ref="eRootWrapper"] {
  border: none !important;
}

.accordion.productAccordion .accordion-item {
  background-color: white !important;
}

.accordion.productAccordion .accordion-button {
  background-color: white !important;
}

.menunav .profile-dropdown .dropdown:hover {
  color: white;
  background-color: #456fb8;
}

.ag-cell-label-container .ag-icon.ag-icon-menu {
  color: white !important;
}
.footerImage {
  filter: grayscale(100%);
  opacity: 0.5;
}

.dashboardCard {
  background-color: var(--appcolor);
  color: white;
}
.selectInputContainer .form-control > div {
  border: none;
}

.incosistncyIconInfo{
  color:red
}

.chart-card{
  background-color: white;
  border: none;
  /* box-shadow: none; */
  padding: 0%;
}

.companyCard{
  font-style: italic;
}

.canceled-series-symbol span{
  color:red;
  text-decoration: line-through;
}